.sigCanvas {
    border: 1px solid #9e9e9e;
}

.button-box {
    background-color: #F594A0;
    border: none;
    padding: 5px 10px;
    margin: 2px 5px;
    color: #fff;
}

.state-select {
    font-size: 14px;
    width: 100%;
    height: 45px;
    padding-left: 10px;
    color: #333;
    border: 2px solid #eceff8;
    background: #eceff8;
    box-shadow: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

@media screen and (max-width: 768px) {
    .hero-desktop-banner {
        display: none;
    }

}

@media screen and (min-width: 768px) {
    .hero-mobile-banner {
        display: none;
    }
}