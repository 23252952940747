/*----- 12. image slider style  -----*/

.single-image {
  overflow: hidden;
  border: 1px solid #f594a0;
  margin-right: 2px;
  // height: 476px;
  a {
    img {
      width: 100%;
      transition: all 0.5s ease 0s;
      transform: scale(1);
      object-fit: cover;
    }
  }
  &:hover a img {
    transform: scale(1.1);
  }
}
